<template>
	<div v-click-outside="close" class="popup">
		<div class="popup-header">
			<h2>{{ title }}</h2>
			<button class="close" @click="closeClick">
				<img alt="Close" src="~/assets/icons/cross.svg">
			</button>
		</div>
		<div class="popup-content">
			<slot/>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	title: {
		type: String,
		required: true,
	},
});

const { setPopup } = usePopup();

function close(e) {
	if (props.title === "Vote") {
		// if captcha is opened
		try {
			const captchas = Array.from(document.querySelectorAll(".g-recaptcha-bubble-arrow"));
			const isCaptchaVisible = captchas.length > 0 &&
				captchas.find((el) => el.parentElement.style.top !== "-10000px") !== undefined;
			if (isCaptchaVisible) {
				return;
			}
		} catch (e) {
			console.error(e);
		}
	}
	if (props.title === "Register") {
		// if captcha is opened
		try {
			const captchas = Array.from(document.querySelectorAll(".g-recaptcha-bubble-arrow"));
			const isCaptchaVisible = captchas.length > 0 &&
				captchas.find((el) => el.parentElement.style.top !== "-10000px") !== undefined;
			if (isCaptchaVisible) {
				return;
			}
		} catch (e) {
			console.error(e);
		}
	}
	setPopup(null);
}

function closeClick() {
	if (props.title === "Vote") {
		// if captcha is opened
		try {
			const captchas = Array.from(document.querySelectorAll(".g-recaptcha-bubble-arrow"));
			const isCaptchaVisible = captchas.length > 0 &&
				captchas.find((el) => el.parentElement.style.top !== "-10000px") !== undefined;
			if (isCaptchaVisible) {
				return;
			}
		} catch (e) {
			console.error(e);
		}
	}
	setPopup(null);
}

onBeforeUnmount(() => {
	const captchas = Array.from(document.querySelectorAll(".g-recaptcha-bubble-arrow"));
	for (const captcha of captchas) {
		captcha.parentElement.remove();
	}
});
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  width: 65%;
  min-height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow-y: auto;
  margin: 1.5em 0;
  background: #edf2f7;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 0 20px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 90%;
  }

  .popup-header {
    position: relative;
    width: 100%;
    background: #cbd5e0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h2 {
      margin: 0;
      padding: 0.33em 0.5em;
      text-align: center;
    }

    .close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scale(1.3);
      right: 0.5em;
      background: none;
      border: none;
      padding: 0.5em;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

:deep(.popup-content form) {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  gap: 1em;

  @media (max-height: 768px) {
    gap: 0.5em;
    padding: 1em 0;
  }
}

:deep(.form-group) {
  display: flex;
  flex-direction: column;
  width: 50%;
}

:deep(.input-group label) {
  color: #2d3748;
  font-size: 1.1rem;
  font-weight: 600;
}

:deep(.input-group input) {
  border: 1px solid #cbd5e0;
  margin-top: 0.33em;
}

:deep(.form-group input::placeholder) {
  color: #cfd6df;
}

:deep(.form-group button) {
  background: #38a169;
  border: 0;
  padding: 0.35em 0.7em;
  color: white;
  font-size: 1.2rem;
  border-radius: 6px;
  cursor: pointer;
  width: fit-content;
  align-self: flex-end;
  white-space: nowrap;

  &.other-button {
    color: #38a169;
    border: 1px solid #38a169;
    background-color: transparent;

    img {
      filter: invert(54%) sepia(33%) saturate(757%) hue-rotate(95deg) brightness(92%) contrast(86%);
    }

    &:hover {
      background-color: #38a169;
      color: #fff;

      img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(5%) saturate(348%) hue-rotate(285deg) brightness(114%) contrast(100%);
      }
    }
  }

  &.other-button-left {
    align-self: flex-start;
  }
}

:deep(
    .form-group button[type="submit"]:hover,
    .form-group button[type="submit"]:focus
  ) {
  background: #2f855a;
}

:deep(.submit-group) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: .5em;
}

:deep(.other-buttons) {
  display: flex;
  gap: .5em;
  flex-flow: wrap;
}

@media (max-width: 968px) {
  :deep(.form-group) {
    width: 90%;
  }
}

@media (max-width: 768px) {
  :deep(.submit-group > button) {
    align-self: flex-start;
  }

  :deep(.submit-group > div) {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  :deep(.submit-group > div > button) {
    align-self: flex-start;

    font-size: 1rem;
  }

  :deep(.submit-group button) {
    margin-left: 0 !important;
  }
}
</style>
